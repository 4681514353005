<template>
    <DataView :value="data" v-if="data" class="pt-5">
        <template #list="slotProps">
            <div class="col-12">

                <div class="flex align-items-center p-2 gap-4">

                    <!-- Icon Section (col-1) -->
                    <div :class="iconBgClass(slotProps.data.type)" class="col-2 flex align-items-center justify-content-center border-round items-center" style="width:2.5rem;height:2.5rem;">
                        <!-- You can place your icon here. I'm using a placeholder div for demonstration. -->
                        <i :class="iconClass(slotProps.data.type)"></i>
                    </div>

                    <!-- Text Content Section (col-9) -->
                    <div class="col-8 flex items-center pl-3 pr-3">
                        <!-- <div class=""> -->
                            <Request 
                            v-if="slotProps.data.type === 'request'" 
                            :data="slotProps.data"
                            @close="handleClose"
                            />
                            <Update v-else-if="slotProps.data.type === 'update'" :data="slotProps.data"/>
                            <div v-else>
                                {{ slotProps.data.type }}
                            </div>
                        <!-- </div> -->
                    </div>

                    <!-- Buttons Section (col-2) -->
                    <div class="col-2 flex items-center">
                        <div v-if="!isReadMode && slotProps.data.type === 'request'">
                            <Button class="bg-red-200 text-red-900" icon="pi pi-thumbs-down" rounded @click.prevent="handleRequest(slotProps.data, 'decline')"></Button>
                            <Button class="bg-green-200 text-green-900 ml-2" icon="pi pi-thumbs-up" rounded @click.prevent="handleRequest(slotProps.data, 'accept')"></Button>
                        </div>
                        <div v-else-if="!isReadMode">
                            <Button icon="pi pi-check" rounded @click.prevent="handleMarkNotificationAsRead(slotProps.data)"></Button>
                        </div>

                        <div v-else-if="isReadMode && slotProps.data.request.type === 'request'">
                            <span :class="decisionClass(slotProps.data.request.meta.status)">{{ absenceDecision(slotProps.data.request.meta.status) }}</span>
                        </div>

                    </div>

                </div>
            </div>
        </template>
    </DataView>

</template>


<script>
import Request from './Request/Request.vue'
import Update from './Update/Update.vue'
export default {
    components: {
        Request, Update
    },

    props: {
        isReadMode: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        }
    },
    data(){
        return {

        }
    },

    emits: ["notificationHandeled", "close"],

    methods: {
        handleClose() {
            this.$emit("close")
        },

        makeRequest(data, decision){
            let type = data.request.type
            let action = data.request.action
            let requestId = data.request.id
    
            let token = this.$store.getters.getJwtToken
            const requestOptions = {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}` 
                },
            };
            
            let url = process.env.VUE_APP_BASE_URL + `requests/handle/${requestId}/${type}/${action}/${decision}/${this.userId}`
            console.log(url)
            return fetch(url, requestOptions)

        },
        updateNotifications(){
            let employeeId = this.$store.getters.getLoggedInUser.uuid
            this.$store.dispatch("updateNotificationsFromDb", employeeId).then(() => {
                this.$emit("notificationHandeled")
            })
        },
        handleRequest(data, decision){
            this.makeRequest(data, decision).then((data) => {
                if(data.ok){
                    this.updateNotifications()
                } 
            })
        },

        handleMarkNotificationAsRead(data){
            let notificatoId = data.id

            let token = this.$store.getters.getJwtToken
            const requestOptions = {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}` 
                },
            };
            
            let url = process.env.VUE_APP_BASE_URL + `notifications/set_read/${notificatoId}`
            fetch(url, requestOptions).then((data) => {
                if(data.ok){
                    this.updateNotifications()
                }
            })

        },

        iconClass(type) {
         switch(type) {
            case 'request':
               return 'pi pi-question text-xl';
            case 'update':
               return 'pi pi-send text-xl';
            case 'info':
               return 'pi pi-info-circle text-xl';
            case 'warning':
               return 'pi pi-exclamation-triangle text-xl';
            default:
               return 'pi pi-align-center text-xl'; // default icon
            }
        },
        iconBgClass(type) {
            switch(type) {
                case 'request':
                return 'bg-green-100';
                case 'update':
                return 'bg-yellow-100';
                case 'info':
                return 'bg-blue-100';
                case 'warning':
                return 'bg-red-100';
                default:
                return 'bg-blue-100'; // default icon
            }
        },

        decisionClass(status) {
            if (status === "accepted") {
                return 'bg-green-100 p-1 border-round';
            } else if (status === "declined") {
                return 'bg-red-100 p-1 border-round';
            } else if (status === "abandoned") {
                return 'bg-yellow-100 p-1 border-round';
            } 
            return '';
        },

        absenceDecision(status){
            if (status === "accepted") {
                return "angenommen"
            } else if (status === "declined") {
                return "abgelehnt"
            } else if (status === "abandoned") {
                return "fallen gelassen"
            } else {
                return "Undefiniert"
            }
        },
    },

    mounted() {
        console.log("notifications")
        console.log(this.data)
    },

    created() {
        console.log("notifications")
        console.log(this.data)
        this.employee = this.$store.getters.getLoggedInUser
        this.userId = this.employee.uuid         
    }
}
</script>