<template>   
    <div class="flex justify-content-center mt-3">
        <SelectButton v-model="showReadNotifications" :options="options" optionLabel="name" optionValue="value" aria-labelledby="basic" />
    </div>
    <DisplayNotifications 
    :isReadMode="showReadNotifications"
    :data="notificationData"
    @notificationHandeled="handleNotificationHandeled"
    @close="handleClose"
    />
    
</template>


<script>
import DisplayNotifications from './DisplayNotifications.vue'

export default {
    components: {
        DisplayNotifications,
    },
    emits: ["notificationUpdate", "close"],
    data() {
        return {
            showReadNotifications: false,
            
            readNotifications: [],
            unreadNotifications: [],
            options: [{name: "Ungelesene Benachrichtigungen", value: false}, {name: "Gelesene Benachrichtigungen", value: true}]
        }
    },

    computed: {
        notificationData(){
            // console.log("computed property is called")
            if (this.showReadNotifications){
                return this.readNotifications
            } else {
                return this.unreadNotifications
            }
        }
    },

    methods: {
        handleClose() {
            this.$emit("close")
        },

        updateState() {
            this.readNotifications = this.$store.getters.getReadNotifications
            // TOFO: revert
            this.unreadNotifications = this.$store.getters.getUnreadNotifications
            console.log("unread notifications in Notifications.vue")
            this.unreadNotifications
        },

        handleNotificationHandeled() {
            // console.log("handleNotificationHandeled -> updating state")
            this.updateState()
            this.$emit("notificationUpdate")
        }
    },

    created() {
        this.updateState()
    }
}
</script>