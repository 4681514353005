<template>
    <!-- <div v-if="hourlyAbsence" class="block">
        <p class="p-0 mb-1 text-600 font-semibold">{{ absenceAction }}</p>
        <h5 class="p-0 m-0">{{hourlyAbsence.request.meta.first_name}} {{hourlyAbsence.request.meta.last_name}}</h5>
        
        <ul>
            <li v-for="(item, index) in hourlyAbsence.request.meta.details" :key="index">
                
                {{ dateToGermanDateStr(new Date(item.start_date_time)) }}: {{ dateToTimeStr(new Date(item.start_date_time)) }} - {{ dateToTimeStr(new Date(item.end_date_time)) }} Uhr
                
            </li>
        </ul>
        
    </div> -->

    <div class="flex  w-full" :class="hourlyAbsence.action !== 'delete' ? 'justify-content-start': 'justify-content-start'"> 
        <div class="block">
            <p class="p-0 mb-1 text-600 font-semibold">{{ absenceAction }}</p>
            <h5 class="p-0 m-0">{{hourlyAbsence.request.meta.first_name}} {{hourlyAbsence.request.meta.last_name}}</h5>
            <ul>
                <li v-for="(item, index) in hourlyAbsence.request.meta.details" :key="index">    
                    {{ dateToGermanDateStr(new Date(item.start_date_time)) }}: {{ dateToTimeStr(new Date(item.start_date_time)) }} - {{ dateToTimeStr(new Date(item.end_date_time)) }} Uhr
                </li>
            </ul>
        </div>

        <div v-if="hourlyAbsence.request.action !== 'delete' && userRole === 'admin'" class="flex justify-content-start align-items-start">
            <Button 
            icon="pi pi-info-circle" 
            iconPos="right" 
            label="Details" 
            class="mr-2 ml-4 button-height" 
            :onclick="showDetails" 
            raised
            :pt="{ 
                label: { class: 'label-text' } 
            }"
            >
            </Button>
        </div>
    </div>

</template>

<script>
import {dateToTimeStr, dateToGermanDateStr} from '../../../js_utilities/dateUtils.js'

export default {
    data() {
        return {
            dateToTimeStr,
            dateToGermanDateStr
        }
    },

    props: {
        hourlyAbsence: {
            type: Object,
            required: true
        }
    },

    computed: {

        absenceAction(){
            if (this.hourlyAbsence.request.action == "create") {
                return "Antrag Gleitzeit"
            } else if (this.hourlyAbsence.request.action == "delete") {
                return "Antrag Löschung Gleitzeit"
            } else {
                return ""
            }
        }
    },

    emits: ["close"],


    methods: {
        showDetails(){
            console.log("showDetails hourly absence details")
            console.log(this.hourlyAbsence)
            let department_id = this.hourlyAbsence.request?.requester_employee?.department?.id || null;
            
            if (department_id !== null){
                let path = `/holidayplanner/${this.hourlyAbsence.request.meta.details[0].start_date_time}/${this.hourlyAbsence.request.meta.details[0].end_date_time}/${department_id}`
                console.log(path)
                this.$router.push({ 
                    path: path, 
                });
            } else {
                let path = `/holidayplanner/${this.hourlyAbsence.request.meta.details[0].start_date_time}/${this.hourlyAbsence.request.meta.details[0].end_date_time}`
                console.log(path)
                this.$router.push({ 
                    path: path, 
                });
            }
            this.$emit("close")

        }
    },

    mounted(){
        console.log("RequestHourlyAbsenceDetails")
        console.log(this.hourlyAbsence)
    },

    created(){
        this.userRole = this.$store.getters.getLoggedInUser.role.name
    }
}
</script>


<style scoped>
.label-text {
    font-size: 1rem !important;
  }

.button-height{
    height: 1.5rem;
}
</style>