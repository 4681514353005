<template>
	<div class="layout-topbar">
		
		<div class="flex justify-content-between flex-wrap card-container" style="width: 100%">
			<div class="flex align-items-center justify-content-center">
				<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
					<i class="pi pi-bars" style="font-size: 1.5rem"></i>
				</button>
			</div>
			<div class="flex align-items-center justify-content-center">
				<button class="p-link layout-topbar-menu-button layout-topbar-button"
					v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
					leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
					<i class="pi pi-ellipsis-v"></i>
				</button>
			</div>

			<div class="flex align-items-center justify-content-center">
				<!--
				<Button class="flex align-items-center justify-content-center h-3rem shadow-2 m-2 mr-5" @click="visibleNotifications = true" label="Benachrichtigungen" :badge="numUnreadNotifications.toString()">
				</Button>
				v-badge="2"
				-->

				<button class="sh-layout-topbar-button" style="" @click="visibleNotifications = true" aria-controls="overlay_menu" aria-haspopup="true">
					<!-- <i :badge="numUnreadNotifications.toString()" class="pi pi-bell p-overlay-badge" style="font-size: 1.5rem"></i> -->
					<i v-badge="numUnreadNotifications.toString()" class="pi pi-bell p-overlay-badge" style="font-size: 1.5rem" />
				</button>

				<UserProfileMenue></UserProfileMenue>
			</div>
		</div>
		
	</div>

	<Sidebar v-model:visible="visibleNotifications" position="right" class="w-6">
		<Notifications @close="handleClose"/>
	</Sidebar>

</template>

<script>

import EventBus from './AppEventBus';
import Notifications from './components/NotificationSystem/Notifications.vue';
import UserProfileMenue from './components/UserProfileMenue.vue';

export default {

	data() {
		return {
			visibleNotifications: false,
			// numUnreadNotifications: 0,
		}
	},

	components: {
		UserProfileMenue, Notifications
	},

	emits: ['menu-toggle', 'topbar-menu-toggle'],
	outsideClickListener: null,
	themeChangeListener: null,
	watch: {
		$route() {
			if (this.active) {
				this.active = false;
				this.unbindOutsideClickListener();
			}
		},
		layoutMode(newValue) {
			this.d_layoutMode = newValue;
		},
	},
	beforeUnmount() {
		EventBus.off('theme-change', this.themeChangeListener);
	},
	mounted() {
		this.themeChangeListener = () => {
			this.applyScale();
		};

		EventBus.on('theme-change', this.themeChangeListener);


	},


    methods: {
		handleClose() {
			this.visibleNotifications = false;
		},

		async logoutUser() {
			await this.$router.push({ name: 'login' });
			this.$store.dispatch('logoutUser')
		},

        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo_blue_white.svg' : 'images/logo_blue_dark.svg';
		},
			toggleConfigurator(event) {
				this.active = !this.active;
				event.preventDefault();

				if (this.active)
					this.bindOutsideClickListener();
				else
					this.unbindOutsideClickListener();
			},
			hideConfigurator(event) {
				this.active = false;
				this.unbindOutsideClickListener();
				event.preventDefault();
			},
			changeInputStyle(value) {
				this.$primevue.config.inputStyle = value;
			},
			changeRipple(value) {
				this.$primevue.config.ripple = value;
			},
			changeLayout(event, layoutMode) {
				this.$emit('layout-change', layoutMode);
				event.preventDefault();
			},
			bindOutsideClickListener() {
				if (!this.outsideClickListener) {
					this.outsideClickListener = (event) => {
						if (this.active && this.isOutsideClicked(event)) {
							this.active = false;
						}
					};
					document.addEventListener('click', this.outsideClickListener);
				}
			},
			unbindOutsideClickListener() {
				if (this.outsideClickListener) {
					document.removeEventListener('click', this.outsideClickListener);
					this.outsideClickListener = null;
				}
			},
			isOutsideClicked(event) {
				return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
			},
			decrementScale() {
				this.scale--;
				this.applyScale();
			},
			incrementScale() {
				this.scale++;
				this.applyScale();
			},
			applyScale() {
				document.documentElement.style.fontSize = this.scale + 'px';
			},
			changeTheme(event, theme, dark) {
				EventBus.emit('theme-change', { theme: theme, dark: dark });
				event.preventDefault();
			}
    },
		changeTheme(event, theme, dark) {
			EventBus.emit('theme-change', { theme: theme, dark: dark });
			event.preventDefault();
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},

		numUnreadNotifications(){
			let unreadNotif = this.$store.getters.getUnreadNotifications
			return unreadNotif.length
		}

	}

}
</script>