<template>
    <UpdateDailyAbsenceDetails
    v-if="data.request.type === 'daily_absence'"
    :data="data"
    />

    <UpdateTimeTrackingDetails
    v-else-if="(data.request.type === 'time_tracking')"
    :data="data"
    />

    <UpdateHourlyAbsenceDetails
    v-else-if="(data.request.type === 'hourly_absence')"
    :data="data"
    />

    <div v-else>
        <h6>
            Not implemented notification type
        </h6>
    </div>
</template>

<script>
import UpdateDailyAbsenceDetails from './UpdateDailyAbsenceDetails.vue'
import UpdateTimeTrackingDetails from '../Update/UpdateTimeTrackingDetails.vue'
import UpdateHourlyAbsenceDetails from './UpdateHourlyAbsenceDetails.vue'

export default {
    components: {
        UpdateDailyAbsenceDetails,
        UpdateTimeTrackingDetails,
        UpdateHourlyAbsenceDetails
    },

    data() {
        return {

        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    mounted(){
        console.log("Update")
        console.log(this.data)
    }
}
</script>