<template>
    <div class="flex justify-content-betweet w-full"> 
        <div class="block">
            <p class="p-0 mb-1 text-600 font-semibold">Antrag Änderung Eintrag Zeiterfassung</p>
            <h5 class="p-0 m-0">{{data.request.meta.first_name}} {{data.request.meta.last_name}}</h5>
            <p class="p-0 m-0">Aktion: <span class="line-through">{{timeTrackingActionOld}}</span> {{timeTrackingActionNew}}</p>
            <p class="p-0 m-0">Zeitpunkt: <span class="line-through">{{timestampOld}}</span> {{timestampNew}}</p>
        </div>
        <div class="flex justify-content-start align-items-start">
            <Button 
            icon="pi pi-info-circle" 
            iconPos="right" 
            label="Details" 
            class="mr-2 ml-4 button-height" 
            :onclick="showDetails" 
            raised
            :pt="{ 
                label: { class: 'label-text' } 
            }"
            >
            </Button>
        </div>
    </div>


    <OverlayPanel ref="op" appendTo="body" showCloseIcon @hide="handleHideOverlay">
        <DataTable 
        :value="timeTrackingEntriesAll" 
        class="p-datatable-lg sh-flex"
        scrollHeight="flex" 
        scrollable
        sortMode="multiple" 
        removableSort 
        v-model:filters="filters"
        filterDisplay="row"
        resizableColumns 
        columnResizeMode="expand"
        >
            <Column field="action" header="Aktion" sortable style="min-width: 15rem">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Nach Aktion filtern" />
                </template>
            </Column>
            <Column field="timestamp" header="Zeitpunkt" sortable style="min-width: 15rem">
                <template #body="slotProps">
                        {{ slotProps.data.timestamp ? format(slotProps.data.timestamp, "dd-MM-yyyy HH:mm") : "" }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Nach Datum filtern" />
                </template>
            </Column>

            <Column field="status" header="Status" sortable style="min-width: 10rem">
                <template #body="slotProps">
                    <span :class="requestStatusClass(slotProps.data.request_action, slotProps.data.request_status)">
                        {{ requestStatusText(slotProps.data.request_action, slotProps.data.request_status) }}
                    </span>

                </template>
            </Column>
        </DataTable>

    </OverlayPanel>

</template>

<script>
import {isoDateTimeStrToDisplayDateTime, isoDateTimeStrToIsoDateStr} from '../../../js_utilities/dateUtils.js'
import { FilterMatchMode } from 'primevue/api';
import {format, parseISO} from 'date-fns'

export default {
    data() {
        return {
            timeTrackingEntriesAll: null,
            filters: {
                action: { value: null, matchMode: FilterMatchMode.CONTAINS },
				timestamp: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            format
        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        timeTrackingActionOld(){
            if (this.data.request.meta.old.action == "check-in") {
                return "Einstempeln"
            } else if (this.data.request.meta.old.action == "check-out") {
                return "Ausstempeln"
            } else {
                return ""
            }
        },

        timeTrackingActionNew(){
            if (this.data.request.meta.new.action == "check-in") {
                return "Einstempeln"
            } else if (this.data.request.meta.new.action == "check-out") {
                return "Ausstempeln"
            } else {
                return ""
            }
        },

        timestampOld(){
            return isoDateTimeStrToDisplayDateTime(this.data.request.meta.old.timestamp)
        },

        timestampNew(){
            return isoDateTimeStrToDisplayDateTime(this.data.request.meta.new.timestamp)
        }
    },

    methods: {
        handleHideOverlay() {
            this.timeTrackingEntriesAll = null
        },

        async getTimeTrackingEntriesDate(isoDateStr, requester_id) {
            let token = this.$store.getters.getJwtToken
            const requestOptions = {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}` 
                },
            };

            let url = process.env.VUE_APP_BASE_URL + `time_tracking/get_entries/${requester_id}/${isoDateStr}`


            return fetch(url, requestOptions).then((data) => {
                return data.json()
            }).then((data) => {

                if (data === null || data === undefined) {
                    return null
                }
                data.forEach(obj => {
                    obj.timestamp = parseISO(obj.timestamp, new Date())
                })
                return data
            })
        },

        showDetails(event){
            this.$refs.op.toggle(event);

            let requester_id = this.data.request.requester_id
            let timestampNew = this.data.request.meta.new.timestamp
            let timestampOriginal = this.data.request.meta.old.timestamp

            let isoDateNew = isoDateTimeStrToIsoDateStr(timestampNew)
            let isoDateOriginal = isoDateTimeStrToIsoDateStr(timestampOriginal)

            if (isoDateNew !== isoDateOriginal) {
                let promise1 = this.getTimeTrackingEntriesDate(isoDateNew, requester_id);
                let promise2 = this.getTimeTrackingEntriesDate(isoDateOriginal, requester_id);

                // Use Promise.all to wait for both promises to resolve
                Promise.all([promise1, promise2])
                    .then(([entries1, entries2]) => {
                        // Combine the results from both promises
                        if (entries1 === null && entries2 === null) {
                            this.timeTrackingEntriesAll = null;
                        } else {
                            // If at least one is not null, concatenate non-null arrays
                            this.timeTrackingEntriesAll = [];
                            if (entries1 !== null) {
                                this.timeTrackingEntriesAll = this.timeTrackingEntriesAll.concat(entries1);
                            }
                            if (entries2 !== null) {
                                this.timeTrackingEntriesAll = this.timeTrackingEntriesAll.concat(entries2);
                            }
                        }
                    })
                    .catch(error => {
                        // Handle any errors that occur during the fetch
                        console.error('Error fetching time tracking entries:', error);
                    });
            } else {
                 this.getTimeTrackingEntriesDate(isoDateOriginal, requester_id).then((data) => {
                     if (data === null || data === undefined) {
                        this.timeTrackingEntriesAll = null
                    } else {
                        this.timeTrackingEntriesAll = data
                    }

                })
            }
        },

        requestStatusClass(action, status) {
            if (action == "delete" && status === "pending"){
                return 'bg-red-100 p-1 border-round';
            } else if (status === "pending") {
                return 'bg-yellow-100 p-1 border-round';
            } else if (status === "accepted") {
                return 'bg-green-100 p-1 border-round';
            }
            return '';
        },

        requestStatusText(action, status) {
            if (action == "delete" && status === "pending"){
                return 'löschen beantragt';
            } else if (status === "pending") {
                return 'beantragt';
            } else if (status === "accepted") {
                return 'akzeptiert';
            }
            return '';
        },
    },

    mounted(){
    }
}
</script>

<style scoped>
.label-text {
    font-size: 1rem !important;
  }

.button-height{
    height: 1.5rem;
}
</style>
