<template>
    <div class="flex  w-full" :class="data.action !== 'delete' ? 'justify-content-start': 'justify-content-start'"> 
        <div class="block">
            <p class="p-0 mb-1 text-600 font-semibold">{{ absenceAction }}</p>
            <h5 class="p-0 m-0">{{data.request.meta.first_name}} {{data.request.meta.last_name}}</h5>
            <p class="p-0 m-0">Aktion: {{timeTrackingAction}}</p>
            <p class="p-0 m-0">Zeitpunkt: {{timeStampFormat}}</p>
        </div>
        <div v-if="data.action !== 'delete'" class="flex justify-content-start align-items-start">
            <Button 
            icon="pi pi-info-circle" 
            iconPos="right" 
            label="Details" 
            class="mr-2 ml-4 button-height" 
            @click="showDetails" 
            raised
            :pt="{ 
                label: { class: 'label-text' } 
            }"
            >
            </Button>
        </div>
    </div>

    <OverlayPanel ref="op" appendTo="body" showCloseIcon @hide="handleHideOverlay">
        <DataTable 
        :value="timeTrackingEntriesAll" 
        class="p-datatable-lg sh-flex"
        scrollHeight="flex" 
        scrollable
        sortMode="multiple" 
        removableSort 
        v-model:filters="filters"
        filterDisplay="row"
        resizableColumns 
        columnResizeMode="expand"
        >
            <Column field="action" header="Aktion" sortable style="min-width: 15rem">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Nach Aktion filtern" />
                </template>
            </Column>
            <Column field="timestamp" header="Zeitpunkt" sortable style="min-width: 15rem">
                <template #body="slotProps">
                        {{ slotProps.data.timestamp ? format(slotProps.data.timestamp, "dd-MM-yyyy HH:mm") : "" }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Nach Datum filtern" />
                </template>
            </Column>

            <Column field="status" header="Status" sortable style="min-width: 10rem">
                <template #body="slotProps">
                    <span :class="requestStatusClass(slotProps.data.request_action, slotProps.data.request_status)">
                        {{ requestStatusText(slotProps.data.request_action, slotProps.data.request_status) }}
                    </span>

                </template>
            </Column>
        </DataTable>

    </OverlayPanel>

</template>

<script>
import {isoDateTimeStrToDisplayDateTime, isoDateTimeStrToIsoDateStr} from '../../../js_utilities/dateUtils.js'
import { FilterMatchMode } from 'primevue/api';
import {format, parseISO} from 'date-fns'


export default {
    data() {
        return {
            timeTrackingEntriesAll: null,
            filters: {
                action: { value: null, matchMode: FilterMatchMode.CONTAINS },
				timestamp: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            format
        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        timeTrackingAction(){
            if (this.data.request.meta.action == "check-in") {
                return "Einstempeln"
            } else if (this.data.request.meta.action == "check-out") {
                return "Ausstempeln"
            } else {
                return ""
            }
        },

        absenceAction(){
            if (this.data.request.action == "create") {
                return "Antrag Eintrag Zeiterfassung"
            } else if (this.data.request.action == "delete") {
                return "Antrag Löschung Eintrag Zeiterfassung"
            } else {
                return ""
            }
        },

        timeStampFormat(){
            return isoDateTimeStrToDisplayDateTime(this.data.request.meta.timestamp)
        }
    },

    methods: {
        handleHideOverlay() {
            this.timeTrackingEntriesAll = null
        },
        async getTimeTrackingEntriesDate(isoDateStr, requester_id) {

            let token = this.$store.getters.getJwtToken
            const requestOptions = {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}` 
                },
            };

            let url = process.env.VUE_APP_BASE_URL + `time_tracking/get_entries/${requester_id}/${isoDateStr}`

            return fetch(url, requestOptions).then((data) => {
                return data.json()
            }).then((data) => {
                if (data === null || data === undefined) {
                    this.timeTrackingEntriesAll = null
                } else {
                    data.forEach(obj => {
                        obj.timestamp = parseISO(obj.timestamp, new Date())
                    })
                    this.timeTrackingEntriesAll = data
                }

                
            })
        },

        showDetails(event){

            let requester_id = this.data.request.requester_id
            let timestamp = this.data.request.meta.timestamp
            let isoDateStr = isoDateTimeStrToIsoDateStr(timestamp)
            this.$refs.op.toggle(event);
            this.getTimeTrackingEntriesDate(isoDateStr, requester_id)

        
        },

        requestStatusClass(action, status) {
            if (action == "delete" && status === "pending"){
                return 'bg-red-100 p-1 border-round';
            } else if (status === "pending") {
                return 'bg-yellow-100 p-1 border-round';
            } else if (status === "accepted") {
                return 'bg-green-100 p-1 border-round';
            }
            return '';
        },

        requestStatusText(action, status) {
            if (action == "delete" && status === "pending"){
                return 'löschen beantragt';
            } else if (status === "pending") {
                return 'beantragt';
            } else if (status === "accepted") {
                return 'akzeptiert';
            }
            return '';
        },


    },
    mounted(){
        console.log("mounted RequestTimeTrackingDetails")
        console.log(this.data)
    }
}
</script>


<style scoped>
.label-text {
    font-size: 1rem !important;
  }

.button-height{
    height: 1.5rem;
}

.sh-flex {
    flex-grow: 1 !important; /* Fill remaining space */
    overflow: auto !important; /* Scroll only the DataTable */
}
</style>
