<template>
    <div class="block" v-if="this.dataCloned">
        <p v-if="this.dataCloned.request.action === 'create'" class="p-0 mb-1 text-600 font-semibold">Ihr Gleitzeitantrag wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.dataCloned.request.action === 'update'" class="p-0 mb-1 text-600 font-semibold">Die Änderung Ihres Gleitzeitantrags wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.dataCloned.request.action === 'delete'" class="p-0 mb-1 text-600 font-semibold">Die Löschung Ihres Gleitzeitantrags wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>

        <h5 class="p-0 m-0">{{dataCloned.request.meta.first_name}} {{dataCloned.request.meta.last_name}}</h5>
        <ul>
            <li v-for="(item, index) in dataCloned.request.meta.details" :key="index">
                
                {{ dateToGermanDateStr(item.start_date_time) }}: {{ dateToTimeStr(item.start_date_time) }} - {{ dateToTimeStr(item.end_date_time) }} Uhr
                
            </li>
        </ul>
    </div>
</template>

<script>
import {cloneDeep} from 'lodash'
import {dateToTimeStr, dateToGermanDateStr} from '../../../js_utilities/dateUtils.js'

export default {
    data() {
        return {
            dataCloned: null,
            dateToTimeStr,
            dateToGermanDateStr
        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {


        absenceDecision(){
            if (this.dataCloned.request.status === "accepted") {
                return "angenommen."
            } else if (this.dataCloned.request.status === "declined") {
                return "abgelehnt."
            } else if (this.dataCloned.request.status === "abandoned") {
                return "fallen gelassen."
            } else {
                return "Undefiniert"
            }
        },

        decisionClass() {
            if (this.dataCloned.request.status === "accepted") {
                return 'bg-green-100 p-1 border-round';
            } else if (this.dataCloned.request.status === "declined") {
                return 'bg-red-100 p-1 border-round';
            } else if (this.dataCloned.request.status === "abandoned") {
                return 'bg-yellow-100 p-1 border-round';
            }
            return ''; 
        }
    },

    mounted(){

        this.dataCloned = cloneDeep(this.data)

        if (Object.prototype.hasOwnProperty.call(this.dataCloned.request.meta.details, 'new')) {
            this.dataCloned.request.meta.details = this.dataCloned.request.meta.details.new;
        }

        console.log("this.dataCloned")
        console.log(this.dataCloned)

        this.dataCloned.request.meta.details =this.dataCloned.request.meta.details.map((obj) => {

            return {
                ...obj,
                start_date_time: new Date(obj.start_date_time),
                end_date_time: new Date(obj.end_date_time)
            }
        })
    }
}
</script>