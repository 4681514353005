<template>
    <div class="flex  w-full" :class="data.action !== 'delete' ? 'justify-content-start': 'justify-content-start'"> 
        <div class="block">
            <p class="p-0 mb-1 text-600 font-semibold">Antrag {{absenceTypeDescription}} <span>{{ absenceAction }}</span></p>
            <h5 class="p-0 m-0">{{data.request.meta.first_name}} {{data.request.meta.last_name}}</h5>
            <p class="p-0 m-0">Von: {{dateToGermanDateStr(new Date(data.request.meta.details.start_date))}}</p>
            <p class="p-0 m-0">Bis: {{dateToGermanDateStr(new Date(data.request.meta.details.end_date))}}</p>
        </div>
        <div v-if="data.request.action !== 'delete' && userRole === 'admin'" class="flex justify-content-start align-items-start">
            <Button 
            icon="pi pi-info-circle" 
            iconPos="right" 
            label="Details" 
            class="mr-2 ml-4 button-height" 
            :onclick="showDetails" 
            raised
            :pt="{ 
                label: { class: 'label-text' } 
            }"
            >
            </Button>
        </div>
    </div>
</template>

<script>
import {dateToGermanDateStr} from '../../../js_utilities/dateUtils.js';
export default {
    data() {
        return {
            userRole: null,
            dateToGermanDateStr
        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    emits: ["close"],

    methods: {
        showDetails(){
            console.log("showDetails")
            console.log(this.data)
            let department_id = this.data.request?.requester_employee?.department?.id || null;
            
            if (department_id !== null){
                this.$router.push({ 
                    path: `/holidayplanner/${this.data.request.meta.details.start_date}/${this.data.request.meta.details.end_date}/${department_id}`, 
                });
            } else {
                this.$router.push({ 
                    path: `/holidayplanner/${this.data.request.meta.details.start_date}/${this.data.request.meta.details.end_date}`, 
                });
            }
            this.$emit("close")

        }

        
    },

    computed: {
        germanDateStrFromIsoDateStr(isoDateStr) {
            return dateToGermanDateStr(new(Date(isoDateStr)))
        },

        absenceTypeDescription(){
            console.log("this.data.request.meta.absence_type")
            console.log(this.data.request.meta.absence_type)
            if (this.data.request.meta.absence_type  === null || this.data.request.meta.absence_type  === undefined) {
                return "Abwesenheit"
            } else {
                return this.data.request.meta.absence_type.name
            }
        },

        absenceAction(){
            if (this.data.request.action == "delete") {
                return "(Löschung)"
            } else if (this.data.request.action == "update") {
                return "(Änderung)"
            } else {
                return ""
            }
        }
    },

    created(){
        console.log("RequestDailyAbsenceDetails")
        console.log(this.data)
        this.userRole = this.$store.getters.getLoggedInUser.role.name
    }
}
</script>

<style scoped>
.label-text {
    font-size: 1rem !important;
  }

.button-height{
    height: 1.5rem;
}
</style>
