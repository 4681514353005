<template>
    <div class="block">
        <p v-if="this.data.request.action === 'create'" class="p-0 mb-1 text-600 font-semibold">{{absenceTypeDescriptionPreFix}} {{absenceTypeDescription}} wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.data.request.action === 'update'" class="p-0 mb-1 text-600 font-semibold">Die Änderung Ihres {{absenceTypeDescription}}s wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <p v-else-if="this.data.request.action === 'delete'" class="p-0 mb-1 text-600 font-semibold">Die Löschung Ihres {{absenceTypeDescription}}s wurde <span :class="decisionClass">{{ absenceDecision }}</span></p>
        <!-- <h5 class="p-0 m-0">{{data.meta.firstName}} {{data.meta.lastName}}</h5> -->
        <p class="p-0 m-0">Von: {{dateToGermanDateStr(new Date(startDate))}}</p>
        <p class="p-0 m-0">Bis: {{dateToGermanDateStr(new Date(endDate))}}</p>
    </div>
</template>

<script>
import {dateToGermanDateStr} from '../../../js_utilities/dateUtils.js';

export default {
    data() {
        return {
            dateToGermanDateStr
        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        absenceTypeDescriptionPreFix(){
            if (this.data.type === "vacation") {
                return "Ihr"
            } else if (this.data.type === "sickness") {
                return "Ihr"
            } else {
                return "Ihre"
            }
        },

        absenceTypeDescription(){
            if (this.data.type === "vacation") {
                return "Urlaubsantrag"
            } else if (this.data.type === "sickness") {
                return "Krankheitsantrag"
            } else {
                return "Abwesenheit"
            }
        },

        absenceDecision(){
            if (this.data.request.status === "accepted") {
                return "angenommen."
            } else if (this.data.request.status === "declined") {
                return "abgelehnt."
            } else if (this.data.request.status === "abandoned") {
                return "fallen gelassen."
            } else {
                return "Undefiniert"
            }
        },

        decisionClass() {
            if (this.data.request.status === "accepted") {
                return 'bg-green-100 p-1 border-round';
            } else if (this.data.request.status === "declined") {
                return 'bg-red-100 p-1 border-round';
            } else if (this.data.request.status === "abandoned") {
                return 'bg-yellow-100 p-1 border-round';
            }
            // console.log("returning nothing")
            return ''; // Default, no background
        },

        startDate(){
            if ("new" in this.data.request.meta.details){
                return this.data.request.meta.details.new.start_date
            } else {
                return this.data.request.meta.details.start_date
            }
        },

        endDate(){ 
            if ("new" in this.data.request.meta.details){
                return this.data.request.meta.details.new.end_date
            } else {
                return this.data.request.meta.details.end_date
            }
        },
    },

    mounted(){
        console.log("UpdateAbsenceDetails")
        console.log(this.data)
    }
}
</script>