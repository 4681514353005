<template>
    <RequestDailyAbsenceDetails
    v-if="(data.request.type === 'daily_absence') && (data.request.action === 'create' || data.request.action === 'delete')"
    :data="data"
    @close="handleClose"
    />

    <RequestDailyAbsenceUpdateDetails
    v-else-if="(data.request.type === 'daily_absence') && data.request.action === 'update'"
    :data="data"
    @close="handleClose"
    />


    <RequestHourlyAbsenceDetails
    v-else-if="(data.request.type === 'hourly_absence') && (data.request.action === 'create' || data.request.action === 'delete')"
    :hourlyAbsence="data"
    @close="handleClose"
    />

    <RequestHourlyAbsenceUpdateDetails
    v-else-if="(data.request.type === 'hourly_absence') && (data.request.action === 'update')"
    :data="data"
    @close="handleClose"
    />


    <RequestTimeTrackingDetails
    v-else-if="(data.request.type === 'time_tracking') && (data.request.action === 'create' || data.request.action === 'delete')"
    :data="data"
    />

    <RequestTimeTrackingUpdateDetails
    v-else-if="(data.request.type === 'time_tracking') && data.request.action === 'update'"
    :data="data"
    />

    <div v-else>
        <h6>
            Not implemented notification type
        </h6>
    </div>
</template>

<script>
import RequestDailyAbsenceDetails from '../Request/RequestDailyAbsenceDetails.vue'
import RequestDailyAbsenceUpdateDetails from '../Request/RequestDailyAbsenceUpdateDetails.vue'
import RequestTimeTrackingDetails from '../Request/RequestTimeTrackingDetails.vue'
import RequestTimeTrackingUpdateDetails from '../Request/RequestTimeTrackingUpdateDetails.vue'
import RequestHourlyAbsenceDetails from '../Request/RequestHourlyAbsenceDetails.vue'
import RequestHourlyAbsenceUpdateDetails from '../Request/RequestHourlyAbsenceUpdateDetails.vue'

export default {
    components: {
        RequestDailyAbsenceDetails,
        RequestDailyAbsenceUpdateDetails,
        RequestTimeTrackingDetails,
        RequestTimeTrackingUpdateDetails,
        RequestHourlyAbsenceDetails,
        RequestHourlyAbsenceUpdateDetails
    },

    data() {
        return {

        }
    },
    emits: ["close"],

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    methods: {
        handleClose() {
            this.$emit("close")
        }
    },

    mounted(){
        // console.log("RequestDetails")
    }
}
</script>