<template>
    <!-- <div v-if="data" class="block">
        <p class="p-0 mb-1 text-600 font-semibold">Antrag Gleitzeitänderung</p>
        <h5 class="p-0 m-0">{{data.request.meta.first_name}} {{data.request.meta.last_name}}</h5>
        
        <ul>
            <li v-for="(item, index) in data.request.meta.details.new" :key="index">
                
                {{ dateToGermanDateStr(new Date(item.start_date_time)) }}: {{ dateToTimeStr(new Date(item.start_date_time)) }} - {{ dateToTimeStr(new Date(item.end_date_time)) }} Uhr
                
            </li>
        </ul>
        
    </div> -->

     <div class="flex  w-full" :class="data.action !== 'delete' ? 'justify-content-start': 'justify-content-start'"> 
        <div class="block">
            <p class="p-0 mb-1 text-600 font-semibold">Antrag Gleitzeitänderung</p>
            <h5 class="p-0 m-0">{{data.request.meta.first_name}} {{data.request.meta.last_name}}</h5>
            <ul>
                <li v-for="(item, index) in data.request.meta.details.new" :key="index">
                    {{ dateToGermanDateStr(new Date(item.start_date_time)) }}: {{ dateToTimeStr(new Date(item.start_date_time)) }} - {{ dateToTimeStr(new Date(item.end_date_time)) }} Uhr
                </li>
            </ul>
        </div>
        <div v-if="data.request.action !== 'delete' && userRole === 'admin'" class="flex justify-content-start align-items-start">
            <Button 
            icon="pi pi-info-circle" 
            iconPos="right" 
            label="Details" 
            class="mr-2 ml-4 button-height" 
            :onclick="showDetails" 
            raised
            :pt="{ 
                label: { class: 'label-text' } 
            }"
            >
            </Button>
        </div>
    </div>

</template>


<script>
import {dateToTimeStr, isoDateTimeStrToIsoDateStr, dateToGermanDateStr} from '../../../js_utilities/dateUtils.js'

export default {
    data() {
        return {
            dateToTimeStr,
            dateToGermanDateStr,
            isoDateTimeStrToIsoDateStr
        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {

    },

    emits: ["close"],


    methods: {
        showDetails(){
            console.log("showDetails")
            console.log(this.data)
            let department_id = this.data.request?.requester_employee?.department?.id || null;
            
            if (department_id !== null){
                let path = `/holidayplanner/${this.data.request.meta.details.new[0].start_date_time}/${this.data.request.meta.details.new[0].end_date_time}/${department_id}`
                console.log(path)
                this.$router.push({ 
                    path: path, 
                });
            } else {
                let path = `/holidayplanner/${this.data.request.meta.details.new[0].start_date_time}/${this.data.request.meta.details.new[0].end_date_time}`
                console.log(path)
                this.$router.push({ 
                    path: path, 
                });
            }
            this.$emit("close")

        }
    },

    mounted(){
        console.log("RequestHourlyAbsenceUpdateDetail")
        console.log(this.data)
        // console.log("RequestFlexWorkUpdateDetails")
        // console.log(this.data)
        // this.data.meta.new.flex_work_entries = this.data.meta.new.flex_work_entries.map((obj) => {

        //     return {
        //         ...obj,
        //         end_time: new Date(obj.end_time),
        //         start_time: new Date(obj.start_time)
        //     }
        // })

        // this.data.meta.original.flex_work_entries = this.data.meta.original.flex_work_entries.map((obj) => {

        //     return {
        //         ...obj,
        //         end_time: new Date(obj.end_time),
        //         start_time: new Date(obj.start_time)
        //     }
        // })

        // console.log(this.data)
    },
    created(){
        this.userRole = this.$store.getters.getLoggedInUser.role.name
    }
}
</script>


<style scoped>
.label-text {
    font-size: 1rem !important;
  }

.button-height{
    height: 1.5rem;
}
</style>